import React, { useContext } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { authContext } from "contexts/AuthContext";
import { checkUserRole } from "utils";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(authContext);
  const { pathname } = useLocation();
  const { loading, data } = auth;
  const { requiredRoles } = rest;
  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }
  // if loading is set to true (when our function useEffect(() => {}, []) is not executed), we are rendering a loading component;
  // if the user is not logged in redirect them to login screen
  if (!auth.data) {
    return <Redirect to="/login" from={rest.path} />;
  }

  if (pathname === "/") {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return checkUserRole(data, requiredRoles) ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to="unauthorized" />
        );
      }}
    />
  );
};

export default ProtectedRoute;
