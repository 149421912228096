import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { ErrorMessage } from "formik";

const FormikInputField = ({
  field: { ...fields },
  form: { touched, errors, setFieldValue, handleBlur },
  ...props
}) => {
  const { label, id, positions } = props;
  const { name } = fields;

  const handleChange = (e) => {
    const { value } = e.target;
    setFieldValue(name, value, true);
  };

  return (
    <FormGroup className="flex-grow-1">
      {label && (
        <Label for={id} className="label-color">
          {label}
        </Label>
      )}
      <Input
        onChange={handleChange}
        invalid={Boolean(touched[name] && errors[name])}
        onBlur={handleBlur}
        {...props}
        {...fields}
      />

      <ErrorMessage
        name={name}
        render={(msg) => {
          return <div className="text-danger font-size-12">{msg}</div>;
        }}
      />
    </FormGroup>
  );
};
export default FormikInputField;
