/* eslint-disable no-console */
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { toast } from "react-toastify";
import { APP_TOKEN } from "../constants";

// console.log("Appollo", process.env.REACT_APP_REST_ENDPOINT);
window.REST_ENDPOINT = process.env.REACT_APP_REST_ENDPOINT;

const ENABLE_WEBSOCKET = false;
let wsLink = null;
let link = null;
const cache = new InMemoryCache();
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
});
if (ENABLE_WEBSOCKET) {
  wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_WS_ENDPOINT}`,
    options: {
      reconnect: true,
    },
  });
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions: { code } }) => {
      switch (code) {
        case "UNAUTHENTICATED":
          console.warn("Error (UNAUTHENTICATED) : ", message);
          // localStorage.clear();
          break;
        case "BAD_USER_INPUT":
          console.error("Error (BAD_USER_INPUT) : ", message);
          toast.error(`Invalid input passed`);
          break;
        case "INTERNAL_SERVER_ERROR":
          console.error("Error (INTERNAL_SERVER_ERROR) : ", message);
          break;
        case "GRAPHQL_VALIDATION_FAILED":
          console.error("Error (GRAPHQL_VALIDATION_FAILED) : ", message);
          toast.error("Validation error occured.");
          break;
        default:
          console.error("Error : ", message);
          toast.error("Something went wrong");
          break;
      }
    });
  } else if (networkError) {
    if (typeof window !== "undefined" && !window.navigator.onLine)
      toast.info(`Sorry, Your browser is offline.`);
    else toast.error("Network Error : Faild to fetch data");
  }
});

const authMiddleware = new ApolloLink((operation, forward) => {
  /*
  NOTE: we have an object stored in the local storage thats why parsing it 
  and then passing the token in header. If your application not storing object then
  change the code to match your logic, also check the userContext and make changes accordingly
  */
  const userData = JSON.parse(localStorage.getItem(APP_TOKEN));

  operation.setContext({
    headers: {
      authorization: (userData && `Bearer ${userData?.token}`) || null,
    },
  });

  return forward(operation);
});
if (ENABLE_WEBSOCKET) {
  link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    ApolloLink.from([errorLink, authMiddleware, httpLink])
  );
} else {
  link = ApolloLink.from([errorLink, authMiddleware, httpLink]);
}

const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    },
  },
});

export default client;
